var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('b-button',{staticClass:"mr-1",attrs:{"size":"sm","disabled":_vm.prev == null,"variant":"none","to":_vm.prev
      ? { name: 'registration-detail', params: { id: _vm.prev.id } }
      : null}},[_c('b-icon',{attrs:{"variant":"primary","icon":"caret-left-fill"}})],1),_c('b-link',{attrs:{"to":{
    name: 'registration-series',
    params: {
      id: _vm.series.id,
      username: _vm.series.username,
    },
  }}},[(_vm.series.year)?_c('span',[_vm._v(_vm._s(_vm.series.year)+" ")]):_vm._e(),_vm._v(_vm._s(_vm.series.name))]),(_vm.next != null)?_c('b-button',{staticClass:"ml-1",attrs:{"size":"sm","variant":"none","to":{ name: 'registration-detail', params: { id: _vm.next.id } }}},[_c('b-icon',{attrs:{"variant":"primary","icon":"caret-right-fill"}})],1):(_vm.createEventParams)?_c('b-link',{staticClass:"ml-3",attrs:{"to":{
    name: 'event-create',
    params: _vm.createEventParams
  }}},[_c('b-icon',{attrs:{"icon":"plus","scale":1.5}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }