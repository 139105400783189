<template>
  <b-container>
    <b-card border-variant="danger" class="mt-5 text-center">
      <b-card-title>The location you are trying to reach does not exist</b-card-title>
    </b-card>
  </b-container>
</template>

<script>
export default {

}
</script>

<style>

</style>
