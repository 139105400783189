import { render, staticRenderFns } from "./Search.vue?vue&type=template&id=20d74eec&scoped=true&"
import script from "./Search.vue?vue&type=script&lang=js&"
export * from "./Search.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "20d74eec",
  null
  
)

export default component.exports